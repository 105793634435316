<template>
  <div id="air-conditionCardWater" @click="goDetails" style="position: relative;">
    <div  class="Name">  
      <img :src="status_src" />    
      <span>{{cardContent.name}}</span>
    </div>
    <div class="Center">      
      <span>{{switchDesc}}</span>
    </div>
    <div class="Bottom">
      <span>{{tempDesc}}</span>
      <span>{{tempValue}}</span>
    </div>
    <div class="v-btn">
      <!-- <a-button  type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(1003)"/> -->
      <a-button v-if="cardContent.cameraId1>0" type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(cardContent.cameraId1)"/>
      <a-button v-if="cardContent.cameraId2>0" type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(cardContent.cameraId2)"/>
    </div >
  </div>
</template>

<script>
import normal_compressor_img from '../../../../public/images/airWater/compressor01.png'
import abCom_compressor_img from '../../../../public/images/airWater/compressor02.png'
import abMach_compressor_img from '../../../../public/images/airWater/compressor03.png'
import main_compressor_img from '../../../../public/images/airWater/compressor04.png'
import mal_compressor_img from '../../../../public/images/airWater/compressor05.png'
import oMal_compressor_img from '../../../../public/images/airWater/compressor06.png'
import normal_screw_img from '../../../../public/images/airWater/screw01.png'
import abCom_screw_img from '../../../../public/images/airWater/screw02.png'
import abMach_screw_img from '../../../../public/images/airWater/screw03.png'
import main_screw_img from '../../../../public/images/airWater/screw04.png'
import mal_screw_img from '../../../../public/images/airWater/screw05.png'
import oMal_screw_img from '../../../../public/images/airWater/screw06.png'

export default {
  name: "air-conditionCard",
  props: {
    siteId:Number,
    cardContent: {
      type: Object,
    },
  },
  data() {
    return {
      tempValue:"",
      tempDesc:"",
      status_src:normal_compressor_img,  
      switch:0, 
      switchDesc:"关",
      analogPointIds:"",
      digitalPointIds:"",
      mutiPointIds:"",   
    }
  },
  methods: {
    goDetails() {
      this.$router.push({
          path: "/homepage/air-conditionwater/airConditionWaterDetails",
          query: {
            siteId: this.siteId,
            analogPointIds: this.analogPointIds,
            digitalPointIds: this.digitalPointIds,
            mutiPointIds: this.mutiPointIds,
            deviceName: this.cardContent.name,
            pv:this.switch,
            switchDesc:this.switchDesc,
            tempValue:this.tempValue,
          },
      });
    },    
    getVideo(cameraId) {
      const data = {
        vdialog: true,
        cameraId: cameraId,
        camera: {
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          cameraId: cameraId,
        }
      }
      console.log('getVideo>>>',data);
      this.$emit('hasVideoChange', data );
    },
  },
  computed:{
    disabled:function(){
      if(this.cardContent.type == 300){
        return false
      }else{
        return true
      }
    },
  },
  mounted() {
    if(this.cardContent.type==180){
      for(let i=0;i<this.cardContent.aoMap.length;i++){      
        if(this.cardContent.aoMap[i].SignalNo == 24){//排气温度
          this.tempDesc = this.cardContent.aoMap[i].SignalName;
          this.tempValue = this.cardContent.aoMap[i].AO_InVal;
          break;
        }        
      } 
      for(let i=0;i<this.cardContent.boMap.length;i++){      
        if(this.cardContent.boMap[i].SignalNo == 2){//开关状态
          this.switch =this.cardContent.boMap[i].BO_InVal;
          this.switchDesc = this.cardContent.boMap[i].BO_InVal==0?"关":"开";
          break;
        }
      }      
    }
    else if(this.cardContent.type==390){
      for(let i=0;i<this.cardContent.boMap.length;i++){      
        if(this.cardContent.boMap[i].SignalNo == 20){//模式状态、控制状态、泵运转状态、泵反馈状态
          if(this.cardContent.boMap[i].SignalName == "模式状态"){
            this.switchDesc = this.cardContent.boMap[i].BO_InVal==0?"制冷":"制热";
            break;
          }
        }
      }  
      for(let i=0;i<this.cardContent.aoMap.length;i++){      
        if(this.cardContent.aoMap[i].SignalNo == 60){//冷水温度
            this.tempDesc = this.cardContent.aoMap[i].SignalName;
            this.tempValue = this.cardContent.aoMap[i].AO_InVal;
            break;
        } 
      }
    }

    for(let i=0;i<this.cardContent.aoMap.length;i++){ 
      if(this.analogPointIds=="") 
        this.analogPointIds = this.cardContent.aoMap[i].SignalPID;
      else
        this.analogPointIds = this.analogPointIds+","+this.cardContent.aoMap[i].SignalPID;     
      switch(this.cardContent.aoMap[i].SignalNo){
        case 4:          
          this.setTemp = this.cardContent.aoMap[i].AO_OutVal;
          break;
        case 7:
          this.curTemp=this.cardContent.aoMap[i].AO_InVal;
          break;
      }      
    }
    // console.log("air-conditionCard1:",this.analogPointIds);    
    for(let i=0;i<this.cardContent.boMap.length;i++){     
      if(this.digitalPointIds=="") 
        this.digitalPointIds = this.cardContent.boMap[i].SignalPID;
      else
        this.digitalPointIds = this.digitalPointIds+","+this.cardContent.boMap[i].SignalPID;        
    }
    // console.log("air-conditionCard2:",this.digitalPointIds);
    for(let i=0;i<this.cardContent.moMap.length;i++){ 
      if(this.mutiPointIds=="") 
        this.mutiPointIds = this.cardContent.moMap[i].SignalPID;
      else
        this.mutiPointIds = this.mutiPointIds+","+this.cardContent.moMap[i].SignalPID;         
    }
    // console.log("air-conditionCard3:",this.mutiPointIds); 
  },
};
</script>

<style scoped>
#air-conditionCardWater {
  width: 200px;
  height: 150px;
  background-color: #f2f4f7;
  border-radius: 3px;
  margin: 0 auto 15px auto;
  border: 1px solid #ffffff;
  transition: 200ms;
}
#air-conditionCardWater:hover {
  border: 1px solid #7682ce;
}
.Name{
  width:100%;
  height: 40%;
  padding-left: 10px;
  padding-top: 10px;
  font-size: 10px;
}
.Center{
  width:100%;
  height: 40%;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 20px;
}
.Bottom{
  width:100%;
  height: 20%;
  padding-left: 20px;
  font-size: 13px;
  padding-bottom: 10px;
}
.v-btn{
	  position: absolute;
	  right: 2%;
	  bottom: 1%;
	  display: grid;
	}
</style>