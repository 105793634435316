<template>
  <div id="equipment">    
    <a-spin :spinning="spinning" class="maina">
      <div class="header">
      <a-form-model :model="form" layout="inline">
        <a-form-model-item :label="$t('doorequipment.a2')">
          <a-select v-model="form.region" style="width: 120px">
            <a-select-option v-for="(item, value) in options" :key="value">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
      <div class="main">
        <my-airConditionCard v-for="(item,value) in airConditionList" :key="value" :siteId="siteId*1" :cardContent="item" @hasVideoChange="hasVideoChange"></my-airConditionCard>
        <div class="empty" v-for="n in 10" :key="'m'+n"></div>
      </div>
    </a-spin>
    <div class="footer">
      <ul class="express">
        <li class="expressli" v-for="(item,index) in icon" :key="index">
          <img :src="item.url" />
          <span>{{item.name}}</span>
        </li>
      </ul>
    </div>
    <!-- 视频弹框 -->
    <camera-dialog :cameraId="cameraId" :vmode="vmode" :starDate="starDate.format('YYYY/MM/DD HH:mm:ss')" :endDate="endDate.format('YYYY/MM/DD HH:mm:ss')"></camera-dialog>
	
  </div>
</template>

<script>
import { getTenantList } from "../../api/door";
import airConditionCard from "./components/airConditionCardWater";
import { getCardList } from "../../api/device";
import cameraDialog from '../monitoring/cameraDialog.vue';
import moment from "moment";

export default {
  data() {
    return {
      form: {
        region: 0,
        region1: 0,
      },
      spinning:false,
      options: [],
      airConditionList: [],
      siteId: this.$route.query.id,
      typeselect: [{ value: "全部" }, { value: "门" }, { value: "认证终端" }],
      icon: [
        {
          name: "正常",
          url: require("../../../public/images/door/equipment01.png"),
        },
        {
          name: "通讯异常",
          url: require("../../../public/images/door/equipment02.png"),
        },
        {
          name: "维护",
          url: require("../../../public/images/door/equipment03.png"),
        },
        {
          name: "机器异常",
          url: require("../../../public/images/door/equipment04.png"),
        },
        {
          name: "故障/报警",
          url: require("../../../public/images/door/equipment05.png"),
        },
        {
          name: "维护报警/上下限警报·运行停止异常",
          url: require("../../../public/images/door/equipment06.png"),
        },
      ],
      cameraId:0,
      vdialog: false,
      vmode:'1',
      starDate:new moment().startOf('day'),
      endDate:new moment(),
    };
  },
  async created() {
    this.spinning = true;
    await getTenantList(this.$route.query.id)
      .then((res) => {
        // console.log(res);
        let { data } = res;
        for (let i = data.length - 1; i > -1; i--) {
          this.options.push({ value: data[i].id, name: data[i].name });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.getCardList(this.options[0].value);    
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        this.spinning = true;
        console.log(this.options[newVal.region].value);
        this.getCardList(this.options[newVal.region].value); 
      },
      deep: true,
    },
  },
  methods: {
    getCardList(tenantId){
      this.spinning = true;
      let par = {
        siteId: this.$route.query.id,
        tenantId: tenantId,
        deviceType: '180,390',
        deviceKind: 2,     
      };
      console.log("AirConditionWater listDetail par:", par);
      getCardList(par)
      .then((res) => {
        console.log("AirConditionWater listDetail res:", res);
        let { data } = res;
        this.airConditionList = data;
        this.spinning = false;
      })
      .catch((err) => {
        console.log("AirConditionWater listDetail err:", err);
        this.spinning = false;
      });
    },
    onSubmit() {
      console.log("submit!", this.form);
    },
    login() {
      this.$router.push("/DoorDetails");
    },
    moment,
    // 卡片视频按钮点击回调函数
    hasVideoChange(data){
      this.vdialog = true;
      this.cameraId = data.cameraId;
    },
  },
  components: {
    "my-airConditionCard": airConditionCard,
    "camera-dialog":cameraDialog,
  },
};
</script>

<style scoped>
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
#equipment {
  height: 100%;
}
.header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 3px 8px #e7e6e6;
  overflow: hidden;
}
.express {
  display: flex;
}
.expressli {
  display: flex;
  align-items: center;
  margin-right: 20px;
  white-space: nowrap;
}
.expressli img {
  margin-right: 5px;
}
.main {
  width: 100%;
  height: calc(100% - 90px);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 20px 20px 20px 20px;
  overflow: auto;
}
.maina{
  width: 100%;
  height: calc(100% - 30px);
  overflow: auto;
}
.empty {
  width: 200px;
  height: 1px;
  margin: 0 auto;
}
.content-ul {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.content-li {
  width: 220px;
  height: 114px;
  padding: 10px 0 20px 20px;
  background: #f3f3f7;
  color: #898989;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px auto;
  border-radius: 5px 5px 5px 5px;
  transition: 200ms;
}
.content-li:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.footer {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
</style>